import { HTMLProps } from "react";

type PanelVariant = "primary" | "secondary";

function getVariantClassName(variant: PanelVariant): string {
    switch (variant) {
        case "primary":
            return "shadow-lg rounded-xxl overflow-hidden";
        case "secondary":
            return "bg-gray-100";
    }
}

export interface PanelProps extends HTMLProps<HTMLDivElement> {
    variant?: PanelVariant;
}

export default function Panel({
    variant = "primary",
    className,
    children,
    ...props
}: PanelProps): JSX.Element {
    const baseClassName = getVariantClassName(variant);
    const finalClassName = baseClassName + (className ? ` ${className}` : "");

    return (
        <div className={finalClassName} {...props}>
            {children}
        </div>
    );
}
