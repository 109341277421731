import PaginatedNfts from "pages/home/PaginatedNfts";
import useSWR from "swr";
import { OnboardingState } from "pages/api/users/onboarding.page";
import Loader from "components/loader";
import Onboarding from "./Onboarding";

export default function FollowingFeed() {
    const { data: onboardingState, isLoading } = useSWR<OnboardingState>(
        "/api/users/onboarding"
    );

    const onboardingRequired = onboardingState?.onboardingRequired;

    if (isLoading) {
        return (
            <div className="flex flex-col items-center mt-20">
                <Loader size="small" />
            </div>
        );
    } else if (onboardingRequired) {
        return <Onboarding onboardingState={onboardingState} />;
    } else {
        return (
            <PaginatedNfts
                baseApiUrl="/api/nfts/following"
                trackEventName="event:following-feed-load-page"
            />
        );
    }
}
