import NftAsset from "components/NftAsset";
import Link from "next/link";
import { useEffect, useRef } from "react";
import { ChainId } from "server/services/ethereum/constants";
import type { Nft } from "server/services/nft";

interface NftCardProps {
    nft: Nft;
    observer: IntersectionObserver;
}

export default function NftCard({ nft, observer }: NftCardProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref?.current);
            return () => observer.disconnect();
        } else {
            return () => {};
        }
    }, [observer]);

    return (
        <div
            id={`${nft.address}-${nft.tokenId}`}
            ref={ref}
            className="flex flex-col justify-center items-center snap-start scroll-smooth h-full w-full pb-32 sm:px-3 pt-20 md:pt-[104px]"
        >
            <Link
                href={`/nft/${ChainId.Mainnet}/${nft.address}/${nft.tokenId}`}
                className="max-h-full h-fit flex-col items-center"
            >
                <NftAsset nft={nft} fullResolution={false} />
            </Link>
        </div>
    );
}
