import { track } from "client/lib/analytics";
import InfiniteScrollTrigger from "components/InfiniteScrollTrigger";
import { Container } from "components/Layout";
import Loader from "components/loader";
import NftDetails from "components/NftDetails";
import {
    addressValidator,
    bigIntStringValidator,
} from "lib/zod/validators.mjs";
import { NftsResponse } from "pages/api/nfts/types";
import { useCallback, useMemo, useState } from "react";
import { Nft } from "server/services/nft";
import useSWRInfinite, { SWRInfiniteKeyLoader } from "swr/infinite";
import { NftIdentifier } from "types";
import NftCard from "./NftCard";

interface PaginatedNftsProps {
    baseApiUrl: string;
    trackEventName: string;
}

export default function PaginatedNfts({
    baseApiUrl,
    trackEventName,
}: PaginatedNftsProps): JSX.Element {
    const { data, size, setSize, isValidating } = useSWRInfinite<NftsResponse>(
        getPageKey(baseApiUrl),
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    );

    const hasMore = data?.[data.length - 1]?.nfts.length !== 0;
    const fetchMore = useCallback(() => {
        void setSize(size + 1);
        track(trackEventName, {
            pageIndex: size + 1,
        });
    }, [size, setSize, trackEventName]);

    const nfts = data?.map((page) => page.nfts).flat();

    const [currentNftId, setCurrentNftId] = useState<NftIdentifier>();

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    for (const entry of entries) {
                        // Make sure that the NFT asset has rendered something that is actually visible
                        if (
                            entry.intersectionRect.width === 0 ||
                            entry.intersectionRect.height === 0
                        ) {
                            return;
                        }

                        const [intersectingAddress, intersectingTokenId] =
                            entry.target.id.split("-");

                        setCurrentNftId({
                            address:
                                addressValidator.parse(intersectingAddress),
                            tokenId:
                                bigIntStringValidator.parse(
                                    intersectingTokenId
                                ),
                        });
                    }
                },
                {
                    threshold: [0.6, 0.8, 1],
                }
            ),
        []
    );

    const currentNft = useMemo<Nft | undefined>(() => {
        if (!currentNftId || !nfts?.length) return undefined;

        return nfts.find(
            (nft) =>
                nft.address === currentNftId.address &&
                nft.tokenId === currentNftId.tokenId
        );
    }, [nfts, currentNftId]);

    return (
        <>
            <div className="absolute left-0 right-0 top-0 bottom-0 overflow-auto snap-y snap-mandatory w-full">
                {nfts &&
                    nfts.map((nft) => (
                        <NftCard
                            key={`${nft.address}-${nft.tokenId}`}
                            nft={nft}
                            observer={observer}
                        />
                    ))}
                <InfiniteScrollTrigger
                    fetchNextPage={fetchMore}
                    stopFetching={!hasMore}
                    isValidating={isValidating}
                />
                {data && hasMore && (
                    <div className="w-full flex justify-center my-10">
                        <Loader size="medium" />
                    </div>
                )}
            </div>
            {currentNft && (
                <Container className="z-20 absolute bottom-0 left-0 right-0 pointer-events-none">
                    <NftDetails nft={currentNft} source="NftDetails" loadData />
                </Container>
            )}
        </>
    );
}

function getPageKey(baseApiUrl: string): SWRInfiniteKeyLoader<NftsResponse> {
    return (pageIndex, previousPageData) => {
        if (pageIndex === 0) return baseApiUrl;

        if (!previousPageData?.cursor) return null;

        const params = new URLSearchParams({
            cursor: previousPageData.cursor,
        });
        return `${baseApiUrl}?${params}`;
    };
}
