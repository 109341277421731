import * as Progress from "@radix-ui/react-progress";
import useTwitter from "client/hooks/useTwitter";
import Button from "components/button";
import useSearch from "context/search";
import { OnboardingState } from "pages/api/users/onboarding.page";
import ConnectTwitterCTA from "./ConnectTwitterCTA";
import RecommendedFollows from "./RecommendedFollows";

interface OnboardingProps {
    onboardingState: OnboardingState;
}

export default function Onboarding({
    onboardingState,
}: OnboardingProps): JSX.Element {
    const { totalFollowsRequired } = onboardingState;

    const { isLoading, isConnected } = useTwitter();

    return (
        <div className="w-full h-full flex flex-col items-center text-center pt-10 pb-20 md:pt-20">
            <div className="text-display-xs">
                {`Follow ${totalFollowsRequired.toString()} accounts to curate your feed`}
            </div>
            <div className="mt-5">
                <ProgressBar onboardingState={onboardingState} />
            </div>
            {!isLoading && !isConnected && (
                <div className="mt-10">
                    <ConnectTwitterCTA />
                </div>
            )}
            <div className="mt-10">
                <SearchButton />
            </div>
            <div className="mt-6">
                <RecommendedFollows />
            </div>
        </div>
    );
}

function ProgressBar({ onboardingState }: OnboardingProps): JSX.Element {
    const { totalFollowsRequired, numFollowing } = onboardingState;

    return (
        <Progress.Root
            className="w-52 h-3 rounded-full overflow-hidden bg-gray-100"
            value={numFollowing}
            max={totalFollowsRequired}
        >
            <Progress.Indicator
                className="h-full bg-black duration-300 ease-in-out"
                style={{
                    width: `${(numFollowing * 100) / totalFollowsRequired}%`,
                }}
            />
        </Progress.Root>
    );
}

function SearchButton(): JSX.Element {
    const { openSearch } = useSearch();

    return (
        <Button
            onClick={() => openSearch("Onboarding")}
            variant="secondary"
            size="small"
        >
            Search for more accounts
        </Button>
    );
}
