import useUser from "client/hooks/useUser";
import { usePageview } from "client/lib/analytics";
import Layout, { Container } from "components/Layout";
import Loader from "components/loader";
import Tabs, { TabsContent, TabsList } from "components/Tabs";
import FollowingFeed from "./FollowingFeed";
import Header from "components/Layout/Header";
import PaginatedNfts from "./PaginatedNfts";
import { useMemo } from "react";
import { Trigger } from "@radix-ui/react-tabs";

export default function HomePage(): JSX.Element {
    const { user, isReady } = useUser();

    usePageview("home");

    const headerCenterContent = useMemo(
        () =>
            isReady && user ? (
                <TabsList className="relative flex justify-center gap-3">
                    <FeedTypeTabTrigger value="for you" label="For You" />
                    <FeedTypeTabTrigger value="following" label="Following" />
                </TabsList>
            ) : null,
        [isReady, user]
    );

    return (
        <Layout showHeader={false}>
            <Tabs defaultValue="for you">
                <Container>
                    <Header centerContent={headerCenterContent} />
                    {!isReady ? (
                        <div className="absolute inset-0 flex justify-center items-center">
                            <Loader size="medium" />
                        </div>
                    ) : user ? (
                        <>
                            <TabsContent value="for you">
                                <PaginatedNfts
                                    baseApiUrl="/api/nfts/for-you"
                                    trackEventName="event:for-you-feed-load-page"
                                />
                            </TabsContent>
                            <TabsContent value="following">
                                <FollowingFeed />
                            </TabsContent>
                        </>
                    ) : (
                        <PaginatedNfts
                            baseApiUrl="/api/nfts/global-feed"
                            trackEventName="event:global-feed-load-page"
                        />
                    )}
                </Container>
            </Tabs>
        </Layout>
    );
}

interface FeedTypeTabTriggerProps {
    label: string;
    value: string;
}

function FeedTypeTabTrigger({ value, label }: FeedTypeTabTriggerProps) {
    return (
        <Trigger
            value={value}
            className="w-32 text-xs md:text-sm py-1 px-5 cursor-pointer rounded-full data-[state=inactive]:hover:bg-gray-100 data-[state=active]:bg-gray-200"
        >
            {label}
        </Trigger>
    );
}
