import { Address } from "lib/zod/types";
import useSWR, { useSWRConfig } from "swr";
import { EthereumAccount } from "types";
import Loader from "components/loader";
import Panel from "components/Panel";
import ProfilePicture from "components/ProfilePicture";
import { accountIdentifier } from "lib/ethereum-string";
import Link from "next/link";
import Button from "components/button";
import useSWRMutation from "swr/mutation";
import { createMutator } from "lib/api";
import { useCallback, useState } from "react";
import { track } from "client/lib/analytics";

export default function RecommendedFollows(): JSX.Element | null {
    const { data: recommendedEthereumAccounts, isLoading } = useSWR<
        EthereumAccount[]
    >("/api/users/recommended-follows");

    const [followedAddresses, setFollowedAddresses] = useState<Address[]>([]);

    const addFollowedAddress = useCallback<(address: Address) => void>(
        (address) => setFollowedAddresses(followedAddresses.concat([address])),
        [followedAddresses, setFollowedAddresses]
    );

    if (isLoading || !recommendedEthereumAccounts) {
        return <Loader size="small" />;
    } else if (recommendedEthereumAccounts.length === 0) {
        return null;
    } else {
        return (
            <div className="flex flex-col gap-5 mt-5">
                {recommendedEthereumAccounts.map((ethereumAccount) => (
                    <RecommendedEthereumAccount
                        key={ethereumAccount.address}
                        ethereumAccount={ethereumAccount}
                        addFollowedAddress={addFollowedAddress}
                        followed={followedAddresses.includes(
                            ethereumAccount.address
                        )}
                    />
                ))}
            </div>
        );
    }
}

interface RecommendedEthereumAccountProps {
    ethereumAccount: EthereumAccount;
    addFollowedAddress: (address: Address) => void;
    followed: boolean;
}

function RecommendedEthereumAccount({
    ethereumAccount,
    addFollowedAddress,
    followed,
}: RecommendedEthereumAccountProps): JSX.Element {
    const { address } = ethereumAccount;

    const [isMutating, setIsMutating] = useState<boolean>(false);

    const { trigger: triggerFollow } = useSWRMutation(
        `/api/users/${address}/follow`,
        createMutator<null>("PUT")
    );

    const { mutate } = useSWRConfig();

    const handleFollow = useCallback(async () => {
        setIsMutating(true);
        track("click:recommended:follow");
        await triggerFollow();
        await mutate("/api/users/onboarding");
        addFollowedAddress(address);
        setIsMutating(false);
    }, [triggerFollow, mutate, address, addFollowedAddress]);

    return (
        <Panel className="w-96 p-5 flex items-center shadow-xs">
            <Link href={`/user/${address}`} className="flex items-center">
                <ProfilePicture size="small" account={ethereumAccount} />
                <div className="ml-5">{accountIdentifier(ethereumAccount)}</div>
            </Link>
            <Button
                onClick={handleFollow}
                disabled={followed || isMutating}
                className="ml-auto"
                size="small"
            >
                {followed ? "Followed" : "Follow"}
            </Button>
        </Panel>
    );
}
